<template>
  <div>
    <div class="header">
      <el-button type="primary" size="mini" @click="createUser"
        >Create</el-button
      >
      <el-input
        style="width: 200px;margin-left: 20px;"
        suffix-icon="el-icon-search"
        v-model="keyword"
        clearable
        size="mini"
        placeholder="Type keyword to search..."
      ></el-input>
      <el-select
        v-model="type"
        style=" margin-left: 20px;"
        placeholder="UserType"
        size="mini"
        clearable
      >
        <el-option
          v-for="item in types"
          :key="item"
          :label="item"
          :value="item"
        ></el-option>
      </el-select>
      <el-select
        v-model="selectedCustomers"
        placeholder="Customer"
        multiple
        collapse-tags
        clearable
        style="margin-left: 20px;"
        size="mini"
      >
        <el-option
          v-for="item in customers"
          :key="item.name"
          :label="item.name"
          :value="item.name"
        ></el-option>
      </el-select>
      <el-button
        @click="getUsers"
        type="primary"
        icon="el-icon-lightning-full"
        style="margin-left: 20px;"
        size="mini"
      ></el-button>
    </div>
    <el-table
      :data="selectedUsers"
      stripe
      size="small"
      :height="tableHeight"
      border
      style="width: 100%; float: left; margin-top: 10px;"
    >
      <el-table-column
        sortable
        prop="username"
        label="Username"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column sortable prop="name" label="Name" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        width="250px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="mobile" label="Mobile" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="type" label="Type" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="customer" label="Customer" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="Action" width="100px">
        <template slot-scope="scope">
          <el-button
            type="primary"
            icon="el-icon-edit"
            circle
            size="mini"
            plain
            @click="edit(scope)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            circle
            size="mini"
            plain
            @click="deleteUser(scope)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div
      style="margin-top: 10px; text-align:center; float:left; height: 30px; line-height:30px; width: 100%;"
    >
      <el-button
        size="mini"
        type="success"
        style=" float:left;"
        icon="el-icon-download"
        @click="exportExcel"
        >Export</el-button
      >
      <span style="color: #606266; font-size:13px;"
        >In total: {{ selectedUsers.length }}</span
      >
    </div>
    <el-dialog :title="title" :visible.sync="dialogFormVisible">
      <user-detail
        v-if="dialogFormVisible"
        @confirm="handleConfirm"
        @cancel="dialogFormVisible = false"
        :userEdit="user"
      ></user-detail>
    </el-dialog>
    <el-table
      id="user_table"
      :data="selectedUsers"
      stripe
      size="small"
      :height="tableHeight"
      border
      style="width: 100%; float: left; margin-top: 10px;"
      v-show="false"
    >
      <el-table-column
        sortable
        prop="username"
        label="Username"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column sortable prop="name" label="Name" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="email"
        label="Email"
        width="250px"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="mobile" label="Mobile" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="type" label="Type" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="customer" label="Customer" show-overflow-tooltip>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import http from "../utils/http";
import UserDetail from "./UserDetail.vue";
import FileSaver from "file-saver";
import XLSX from "xlsx";
export default {
  components: {
    UserDetail,
  },
  data() {
    return {
      title: "Create User",
      keyword: "",
      users: [],
      customers: [],
      types: ["external", "internal"],
      dialogFormVisible: false,
      tableHeight: window.innerHeight - 200,
      type: "",
      selectedCustomers: [],
      user: {},
    };
  },
  mounted() {
    this.getCustomers();
    this.getUsers();
  },
  computed: {
    selectedUsers() {
      var kw = this.keyword.toLowerCase();
      return this.users.filter((value) => {
        return (
          (value.username.toLowerCase().indexOf(kw) > -1 ||
            value.name.toLowerCase().indexOf(kw) > -1 ||
            value.email.toLowerCase().indexOf(kw) > -1 ||
            value.mobile.toLowerCase().indexOf(kw) > -1) &&
          (this.type == "" ? true : value.type == this.type) &&
          (this.selectedCustomers.length > 0
            ? this.selectedCustomers.indexOf(value.customer) > -1
            : true)
        );
      });
    },
  },
  methods: {
    exportExcel() {
      /* generate workbook object from table */
      var wb = XLSX.utils.table_to_book(document.querySelector("#user_table"));
      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "user_list.xlsx"
        );
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
      }
      return wbout;
    },
    handleConfirm() {
      this.dialogFormVisible = false;
      this.getUsers();
    },
    createUser() {
      this.title = "Create User";
      this.user = {};
      this.dialogFormVisible = true;
    },
    edit(scope) {
      this.title = "Edit User";
      this.user = scope.row;
      this.dialogFormVisible = true;
    },
    deleteUser(scope) {
      if (scope.row._id == "") {
        this.users.splice(scope.$index, 1);
      } else {
        http
          .post("/user/deleteUser", { username: scope.row.username })
          .then((res) => {
            if (res.data.status === 1) {
              this.$message({
                message: "the user was deleted successfully!",
                type: "success",
              });
              this.users.splice(scope.$index, 1);
            } else {
              this.$message.error(res.data.message);
            }
          });
      }
    },
    saveUser(scope) {
      if (scope.row._id == "") {
        var user = {
          username: scope.row.username,
          name: scope.row.name,
          customer: scope.row.customer,
          mobile: scope.row.mobile,
          email: scope.row.email,
          type: scope.row.type,
        };
        http.post("/user/addUser", user).then((res) => {
          if (res.data.status === 1) {
            this.$message({
              message: "User was created successfully",
              type: "success",
            });
            scope.row.disabled = true;
            scope.row._id = res.data.data.insertedId;
          } else {
            this.$message.error(res.data.message);
          }
        });
      } else {
        var user2 = {
          username: scope.row.username,
          name: scope.row.name,
          customer: scope.row.customer,
          mobile: scope.row.mobile,
          email: scope.row.email,
          type: scope.row.type,
        };
        http.put("/user/updateUser", user2).then((res) => {
          if (res.data.status === 1) {
            scope.row.disabled = true;
            this.$message({
              message: "The user was saved successfully!",
              type: "success",
            });
          } else {
            this.$message.error(res.data.message);
          }
        });
      }
    },
    addUser() {
      this.users.push({ disabled: false, _id: "" });
    },
    getCustomers() {
      http.get("/customer/getCustomer").then((res) => {
        if (res.data.status === 1) {
          this.customers.splice(0, this.customers.length);
          res.data.data.forEach((value) => {
            this.customers.push(value);
          });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getUsers() {
      http.post("/user/getUserByKeyword").then((res) => {
        if (res.data.status === 1) {
          this.users.splice(0, this.users.length);
          this.users = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  width: 1000px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  float: left;
  /* margin-top: -10px; */
}
</style>
